<template>
    <el-container style="width: 100%">
        <el-header>
            <h2 style="text-align: left">绑定手机号</h2>
        </el-header>
        <el-form :model="form" size="small" label-width="75px" style="margin-top: 5%" :rules="rules" ref="form">
            <el-row>
                <el-col :span="20">
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="form.mobile" maxlength="11" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="验证码">
                        <el-input v-model="form.verifyCode" maxlength="6" placeholder="输入验证码"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" :push="2">
                    <el-button size="small" @click="sendVerificationCode(form.mobile)" v-if="!sendMsgDisabled">
                        获取验证码
                    </el-button>
                    <el-button disabled size="small" v-if="sendMsgDisabled">{{ time + '秒后获取' }}</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-footer style="margin-bottom: 5%; text-align: center; float: bottom">
            <el-button style="width: 100px" type="primary" @click="bind" size="small">确定</el-button>
        </el-footer>
    </el-container>
</template>
<script type="text/javascript">
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';

export default {
    name: 'WechatBindMobile',
    data() {
        return {
            form: {
                mobile: '',
                verifyCode: '',
                key: '',
            },
            time: 60, // 发送验证码倒计时
            sendMsgDisabled: false,
            rules: { mobile: mobileValidateRule(true) },
        };
    },
    mounted() {},
    created() {
        document.title = '绑定手机号';
    },
    methods: {
        // 发送验证码
        sendVerificationCode(mobile) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const that = this;
                    this.$http.get('/wechat/sendVerificationCode?mobile=' + mobile).then((res) => {
                        const response = res.data;
                        // 验证码发送成功 60秒后 才可以点击
                        if (response.status === 200) {
                            that.sendMsgDisabled = true;
                            const interval = window.setInterval(function () {
                                if (that.time-- <= 0) {
                                    that.time = 60;
                                    that.sendMsgDisabled = false;
                                    window.clearInterval(interval);
                                }
                            }, 1000);
                        }
                    });
                }
            });
        },
        // 绑定手机号提交
        bind() {
            this.form.key = this.$route.query.key;
            this.$http.post('/wechat/bind', this.form).then((res) => {
                const response = res.data.data;
                // 跳转到积分查询页面
                this.$router.push({ path: '/wechatPoint', query: response });
            });
        },
    },
};
</script>
<style>
html,
body {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
}
</style>
